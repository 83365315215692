html
    -webkit-touch-callout: none !important
    user-select: none !important

body
    box-sizing: border-box
    display: flex
    justify-content: center
    align-items: center
    position: relative
    background: center / cover no-repeat, linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../img/zz.png) rgb(248, 249, 250)
    background-position: center
    &::before
        content: ''
        display: block
        position: absolute
        left: 0
        top: 0
        right: 0
        bottom: 0
        background: center / cover no-repeat, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(../img/zz.png) rgb(248, 249, 250)
        background-position: center
        opacity: 0
        transition: all 0.3s
    #main
        width: 375px
        margin: 0 auto 0
        box-sizing: border-box
        border-radius: 8px
        overflow: hidden
        position: relative
        z-index: 1
        box-shadow: 0 0 50px -5px rgba(0, 0, 0, 0.4)
        color: #eee
        font-size: 0
        opacity: 1
        transition: all 0.3s
        &::before
            z-index: -2
            content: ''
            position: absolute
            width: 100%
            height: 100%
            left: 0
            top: 0
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
            // filter: blur(5px)
            // background: url("../img/zz.png") center center fixed
            background-color: #1bced4
            background-image: linear-gradient(123deg, rgba(27, 206, 212, 0.7) 0%, rgba(17, 28, 203, 0.7) 100%)
            opacity: 0.7
        .top
            position: relative
            &::after,.slogan
                cursor: pointer
                position: absolute
                top: 0
                left: 0
                width: 100%
                height: 100%
                transition: all 0.3s
                opacity: 0
            &::after
                z-index: 1
                content: ''
                background: rgba(0,0,0,0.8)
            .slogan
                background-image: url("//api.zhuzhou.city/fun/people2/daily/pic")
                background-size: cover
                z-index: 2
                font-size: 40px
                -webkit-text-fill-color: transparent
                -webkit-background-clip: text
                display: flex
                justify-content: center
                align-items: center
                opacity: 0
            &:hover
                &::after,.slogan
                    opacity: 1
            .cover
                max-width: 100%
                min-width: 100%
            .des
                width: 100%
                position: absolute
                left: 0
                bottom: 0
                font-size: 12px
                padding: 15px 10px 5px 10px
                color: #fff
                background: linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.5) 50%,rgba(255,255,255,0.5) 100%)
                box-sizing: border-box
                text-align: justify
                &:empty
                    opacity: 0
        .bottom
            padding: 10px 15px 15px
            .title
                color: rgba(255,255,255,0.9)
                text-shadow: 0 0 3px #333
                font-size: 16px
                width: 100%
            .wrap
                display: flex
                flex-wrap: wrap
                .box
                    width: 50%
                    height: 40px
                    display: inline-block
                    // background: red
                    flex: 1 1 calc( 50% - 10px )
                    margin: 10px 0
                    font-size: 12px
                    transition: all 0.3s
                    display: flex
                    align-items: center
                    justify-content: center
                    box-sizing: border-box
                    position: relative
                    &:nth-child(odd)
                        margin-right: 10px
                    .makeBy
                        position: absolute
                        right: -5px
                        top: -8px
                        width: 20px
                        &.no
                            opacity: 0.1
body.showMap
    &::before
        opacity: 1
    #main
        opacity: 0